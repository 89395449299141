// ** Utils
import { http } from 'src/@core/utils/http';

// ** Config
import apiConfig from 'src/configs/api';

// ** Redux
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// ** Get Solutions
export const getSolutions = createAsyncThunk('solutions/getSolutions', async (_params, { rejectWithValue }) => {
  try {
    const response = await http.get(apiConfig.getSolutionsEndpoint, {
      params: {
        page_size: 999,
      },
    });
    const data = response.data;

    return {
      solutions: data.results || [],
    };
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

// ** Create Solution
export const createSolution = createAsyncThunk('solutions/createSolution', async (params, { rejectWithValue }) => {
  try {
    const response = await http.post(apiConfig.createSolutionEndpoint, {
      ...params,
    });
    const data = response.data;

    return {
      data: data,
    };
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

// ** Edit Solution
export const editSolution = createAsyncThunk('solutions/editSolution', async (params, { rejectWithValue }) => {
  try {
    const response = await http.patch(apiConfig.editSolutionEndpoint.replace(':name', params.name), params);
    const data = response.data;

    return {
      solution: data,
    };
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

// ** Delete Solution
export const deleteSolution = createAsyncThunk('solutions/deleteSolution', async (params, { rejectWithValue, dispatch }) => {
  try {
    const response = await http.delete(apiConfig.deleteSolutionEndpoint.replace(':name', params.name));
    const data = response.data;

    dispatch(getSolutions());

    return {
      data: data,
    };
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

// ** Get Solution
export const getSolution = createAsyncThunk('solutions/getSolution', async (params, { rejectWithValue }) => {
  try {
    const response = await http.get(apiConfig.getSolutionEndpoint.replace(':name', params.name));
    const data = response.data;

    return {
      solution: data || {},
    };
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

// ** Get Solution History
export const getSolutionHistory = createAsyncThunk('solutions/getSolutionHistory', async (params, { rejectWithValue }) => {
  try {
    const response = await http.get(apiConfig.getSolutionHistoryEndpoint.replace(':name', params.name), {
      params: {
        page: params.page || 1,
        page_size: 20,
      },
    });
    const results = response.data?.results || [];

    return {
      history: results,
    };
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

// ** Upload Photo
export const uploadPhoto = createAsyncThunk('solutions/uploadPhoto', async (params, { rejectWithValue }) => {
  try {
    const fd = new FormData();
    fd.append('file', params.file);

    const config = { headers: { 'Content-Type': 'multipart/form-data' } };

    const response = await http.post(apiConfig.photoUploadEndpoint, fd, config);
    const data = response.data;

    return {
      file: data.file || null,
    };
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const solutionsSlice = createSlice({
  name: 'solutions',
  initialState: {
    isLoadingSolutions: false,
    isLoadingSolution: false,
    isLoadingSolutionHistory: false,
    solutions: [],
    solution: {},
    solutionHistory: [],
    solutionsError: false,
    solutionError: false,
  },
  reducers: {
    resetSolution: (state) => {
      state.isLoadingSolution = false;
      state.solutionError = false;
      state.solution = {};
    },
    resetSolutionHistory: (state) => {
      state.solutionHistory = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSolutions.pending, (state) => {
        state.isLoadingSolutions = true;
        state.solutionsError = false;
      })
      .addCase(getSolutions.fulfilled, (state, action) => {
        state.solutions = action.payload.solutions;

        state.isLoadingSolutions = false;
      })
      .addCase(getSolutions.rejected, (state, action) => {
        state.isLoadingSolutions = false;

        state.solutionsError = action.payload?.detail || 'Произошла ошибка';
      });
    builder
      .addCase(getSolution.pending, (state) => {
        state.isLoadingSolution = true;

        state.solutionError = false;
      })
      .addCase(getSolution.fulfilled, (state, action) => {
        state.solution = action.payload.solution;

        state.isLoadingSolution = false;
      })
      .addCase(getSolution.rejected, (state, action) => {
        state.isLoadingSolution = false;

        state.solutionError = action.payload?.detail || 'Произошла ошибка';
      });
    builder
      .addCase(getSolutionHistory.pending, (state) => {
        state.isLoadingSolutionHistory = true;
      })
      .addCase(getSolutionHistory.fulfilled, (state, action) => {
        state.solutionHistory = action.payload.history || [];

        state.isLoadingSolutionHistory = false;
      })
      .addCase(getSolutionHistory.rejected, (state) => {
        state.isLoadingSolutionHistory = false;
        state.solutionHistory = [];
      });
  },
});

export const { resetSolution, resetSolutionHistory } = solutionsSlice.actions;

export default solutionsSlice.reducer;
